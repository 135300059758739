import 'reflect-metadata';
import {injectable} from 'tsyringe';
import {store} from '@/store';


@injectable()
export class InformationSandbox {
    private store = store;

    constructor() {
    }

    getInformation(payload:any): void {
        this.store.dispatch('getInformation', payload);
    }
}
