<style src="./information.component.scss" lang="scss"></style>

<template>
  <section class="about">
    <div class="container-fluid v-container">
      <div class="row">
        <div class="col-12">
          <v-card class="about__info mt-40 col-md-12">
            <img class="mr-8" src="@/assets/images/common/ministry-logo-blue.svg" alt="ministry-logo">
          </v-card>
          <v-card class="mt-20 mb-40 col-md-12">
            <ul class="about__list">
              <li>
                {{ aboutData[0].title }}
                <span>
                  <a href="tel:1655">{{ aboutData[0].data }}</a>
                </span>
              </li>
              <li>
                {{ aboutData[1].title }}
              <span>
                <a href= "mailto:info@azintelecom.az">mincom@mincom.gov.az</a>
              </span>
              </li>
            </ul>
            <div>
              <v-divider/>
              <h4>{{ content.title }}</h4>
              <p v-html="content.contentHTML"></p>
            </div>
          </v-card>
        </div>
      </div>
    </div>
  </section>
</template>

<script src="./information.component.ts"></script>
