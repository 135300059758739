import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import {AboutSandbox} from '@/pages/about/sandbox/about.sandbox';
import {container} from 'tsyringe';
import {store} from '@/store';
import {IAboutContentCategory} from '@/pages/about/types/about-data';
import {IInformation} from '@/pages/information/types/information';
import {InformationSandbox} from '@/pages/information/sandbox/information.sandbox';

@Options({
    name: 'about-component',
})

export default class InformationComponent extends Vue {
    private sandbox: InformationSandbox = container.resolve(InformationSandbox);
    public aboutData = [
        {
            id: 1,
            title: 'Telefon',
            data: '1655 Çağrı Mərkəzi'
        },
        {
            id: 2,
            title: 'Email',
            data: 'https://info@azintelecom.az'
        }
    ];

    get content(): IInformation{
        return store.getters._getInformation;
    }

    public created(): void {
        this.sandbox.getInformation(this.$route.params.id);
    }
}
